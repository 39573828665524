.inter {
    font-family: 'Inter', sans-serif;
}

.courier {
    font-family: 'Courier New', Courier, monospace;
}

.centerDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.lightText {
    font-weight: lighter;
}

.normalText {
    font-weight: normal;
}

.top {
    top: 10px; 
}

.left {
    left: 10px;
}

.absolute {
    position: absolute;
}

.cleanLink {
    text-decoration: none;
    color: black; 
}

.bold {
    font-weight: bold;
}

.fade-in {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

.square {
    width: 30px;
    height: 30px;
}

.color1 {
    background-color: #4A4063;
}

.color2 {
    background-color: #5C6B73;
}

.color3 {
    background-color: #DFA06E;
}

.color4 {
    background-color: #F5F3BB;
}

.color5 {
    background-color: #2CA58D;
}

.marginBottom {
    margin-bottom: 50px;
}

.blackEmoji {
    color: transparent; 
    text-shadow: 0 0 0 black;
}

/* Large screen */ 
@media only screen and (min-width: 801px) {
    .titleFont {
        font-size: 3vw;
    }
    .normalFont {
        font-size: calc(0.15rem + 1vw);
    }

    .smallFont {
        font-size: calc(0.15rem + 0.7vw);
    }

    .activityLabel {
        font-size: calc(0.5rem + 1vw);
    }

    .activityTitle {
        font-size: calc(0.5rem + 1.5vw);
    }

    .leftDiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 35vw;
    }

    .graph {
        width: 500px; 
        height: 400px; 
    }
    .errorPanel {
        background-color: #FFD1D1;
        border-radius: 12px;
        width: 30%;
        padding-left: 1%;
        padding-right: 1%;
    }
}

.custom-tooltip {
    background-color: white;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-color: grey;
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
}

@media only screen and (max-width: 800px) {
    .titleFont {
        font-size: 150%;
        margin-top: 50px;
    }

    .graph {
        width: 320px; 
        height: 300px; 
    }

    .leftDiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10vw;
    }
    .errorPanel {
        background-color: #FFD1D1;
        border-radius: 12px;
        width: 80%;
        padding-left: 1%;
        padding-right: 1%;
    }
}
