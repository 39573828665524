.flexLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.flexHorLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.marginTop {
    margin-top: 25px;
}

.marginRight {
    margin-right: 25px;
}

.marginBottomSmall {
    margin-bottom: 10px;
}