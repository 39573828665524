.rootdiv {
    height: 100%; 
    width: 100%;
}

.homediv {
    width: 98vw; 
    page-break-after: always;
}

.flexdiv {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.textParent {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
}

/* Fullscreen */ 
@media only screen and (min-width: 801px) {
    .titleText {
        font-family: 'Inter';
        font-size: calc(4vw + 1rem);
        font-weight: bold;
        text-align: center;
        margin-top: 27vh;
        margin-left: 10vw;
        margin-bottom: 0%;
    }
    .lightText {
        font-weight: lighter;
        font-size: calc(0.25rem + 0.75vw);
    }
    .massiveText {
        font-size: calc(2vw + 2rem);
    }
    .imagediv {
        width: 100%;
        z-index: 2;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .copyText {
        font-size: calc(0.5rem + 1vw);
        font-family: 'Lato';
        font-weight: lighter;
        text-align: center;
        width: 70%;
        padding-left: 25%;
    }

    .buttonParent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10%;
        gap: 10px; 
    }
    .tutorialDiv {
        width: 50%; 
        margin-left: 10%;
        margin-bottom: 25px;
    }
    .codeBlock {
        background-color: #3A3A3A;
        border-radius: 15px;
        width: 100%;
        padding-top: 3%;
        padding-bottom: 3%;
        padding-left: 2%;
    }
    .extraLargeText {
        font-size: calc(1rem + 1vw);
    }
    .normalFont {
        font-size: calc(0.15rem + 1vw);
    }

    .largeText {
        font-size: calc(0.25rem + 1vw);
    }
    .button {
        text-align: center;
        color: white;
        border-radius: 12px;
        font-size: normal;
        font-family: 'Lato';
        height: 50px;
        width: 200px;
        justify-content: space-between;
        cursor: pointer;
        display: inline-block;
        border: 0px;
    }
}

/* Extra large screen */
@media only screen and (min-width: 2000px) {
    .button {
        text-align: center;
        color: white;
        border-radius: 12px;
        font-size: calc(0.25rem + 1vw);
        font-family: 'Lato';
        height: 100px;
        width: 400px;
        justify-content: space-between;
        cursor: pointer;
        display: inline-block;
        border: 0px;
    }

}

/* Small screen */ 
@media only screen and (max-width: 800px) {
    .imagediv {
        width: 0%;
    }
    .massiveText {
        font-size: calc(1.5vw + 2rem);
    }

    .copyText {
        font-size: 135%;
        font-family: 'Lato';
        font-weight: lighter;
        text-align: center;
        width: 50%;
        padding-left: 25%;
    }

    .titleText {
        font-family: 'Inter';
        font-size: 300%;
        font-weight: bold;
        text-align: center;
        padding-top: 15vh;
        margin-bottom: 0%;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .buttonParent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .tutorialDiv {
        width: 80%; 
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 25px;
    }
    .normalFont {
        font-size: normal;
    }

    .largeText {
        font-size: large;
    }
    .extraLargeText {
        font-size: 200%;
    }
    .codeBlock {
        background-color: #3A3A3A;
        border-radius: 15px;
        width: 100%;
        padding-top: 9%;
        padding-bottom: 3%;
        padding-left: 2%;
    }
    .lightText {
        font-weight: lighter;
    }
    .button {
        text-align: center;
        color: white;
        border-radius: 12px;
        font-size: normal;
        font-family: 'Lato';
        height: 50px;
        width: 200px;
        justify-content: space-between;
        cursor: pointer;
        display: inline-block;
        border: 0px;
    }

}

.button:hover {
    transform: scale(1.02); 
}

.button:active {
    transform: translateY(4px); 
}

.colorPurple {
    background-color: #4A4063;
}

.colorGreen {
    background-color: #5C6B73;
}

.dot {
    height: 15px; 
    width: 15px; 
    background-color: #FF8B8B;
    border-radius: 50%;
}

.top {
    top: 10px; 
}

.absolute {
    position: absolute; 
}

.relative {
    position: relative; 
}

.bottom {
    bottom: 10px; 
}

.left {
    left: 10px; 
}

.topHigh {
    top: 0px;  
}

.right {
    right: 20px; 
}

.onTop {
    z-index: 10;
}

.interFont {
    font-family: 'Inter';
}

.boldText {
    font-weight: bold;
    color: black; 
}

.courierFont {
    font-family: 'Courier New';
}

.cleanLink {
    text-decoration: none;
    color: black; 
}

.textCenter {
    text-align: center;
    width: 100%;
    height: 100%;
}

.white {
    color: white; 
}

.fade-in {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

.hidden {
    display: none;
}

.topMargin {
    margin-top: 15px; 
}

.blackEmoji {
    color: transparent; 
    text-shadow: 0 0 0 black;
}